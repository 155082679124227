<template>
  <el-card shadow="hover" header="" class="x-card-title">
    <el-form :model="shopDataForm" ref="shopDataForm" label-width="200px" class="demo-ruleForm">
      <el-form-item label="企业名称" prop="company_name">
        <el-input v-model="shopDataForm.company_name"></el-input>
      </el-form-item>
      <el-form-item label="注册资金" prop="reg_money">
        <el-input v-model="shopDataForm.reg_money"></el-input>
      </el-form-item>
      <el-form-item label="公司电话" prop="company_phone">
        <el-input v-model="shopDataForm.company_phone"></el-input>
      </el-form-item>
      <el-form-item label="法人姓名" prop="legal_name">
        <el-input v-model="shopDataForm.legal_name"></el-input>
      </el-form-item>
      <el-form-item label="营业执照号" prop="license_num">
        <el-input v-model="shopDataForm.license_num"></el-input>
      </el-form-item>
      <el-form-item label="企业地址" prop="company_address">
        <el-input v-model="shopDataForm.company_address"></el-input>
      </el-form-item>
      <el-form-item label="电子邮箱" prop="company_email">
        <el-input v-model="shopDataForm.company_email"></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="link_phone">
        <el-input v-model="shopDataForm.link_phone"></el-input>
      </el-form-item>
      <el-form-item label="法人身份证号" prop="legal_id">
        <el-input v-model="shopDataForm.legal_id"></el-input>
      </el-form-item>
      <el-form-item label="营业执照有效期" prop="order_time_range">
        <el-date-picker
          style="width: 400px;"
          v-model="order_time_range"
          type="daterange"
          align="center"
          size="medium"
          :editable="false"
          unlink-panels
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="营业执照" prop="licence_img">
        <el-upload
          class="site-logo"
          :action="MixinUploadApi"
          :show-file-list="false"
          :on-success="(res) => { shopDataForm.licence_img = res.url }"
          :multiple="false"
        >
          <img
            v-if="shopDataForm.licence_img"
            :src="shopDataForm.licence_img"
            class="site-logo-img"
          />
          <i v-else class="el-icon-plus logo-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="handleSaveShopData()" style="width: 400px;">保存</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
import * as API_account from "@/api/account";
export default {
  name: "safeSetting",
  data() {
    return {
      shopDataForm: {
        // shop_name: "",
        reg_money: "",
        company_phone: "",
        legal_name: "",
        license_num: "",
        company_email: "",
        link_phone: "",
        company_name: "",
        company_address: "",
        legal_id: "",
        licence_start: "",
        licence_end: "",
        licence_img: ""
      },
      order_time_range: [],
      loading: false
    };
  },
  mounted() {
    this.GET_List();
  },
  watch: {
    // order_time_range: {
    //   deep: true,
    //   handler() {
    //     this.shopDataForm.licence_start =
    //       this.order_time_range[0].getTime() / 1000;
    //     this.shopDataForm.licence_end =
    //       this.order_time_range[1].getTime() / 1000;
    //   }
    // }
  },
  methods: {
    GET_List() {
      API_account.getShopmsg().then(res => {
        //    company_name reg_money company_phone legal_name license_num
        // company_address company_email link_phone legal_id licence_img
        this.shopDataForm.company_name = res.company_name;
        this.shopDataForm.reg_money = res.reg_money;
        this.shopDataForm.company_phone = res.company_phone;
        this.shopDataForm.legal_name = res.legal_name;
        this.shopDataForm.license_num = res.license_num;
        this.shopDataForm.company_address = res.company_address;
        this.shopDataForm.company_email = res.company_email;
        this.shopDataForm.link_phone = res.link_phone;
        this.shopDataForm.legal_id = res.legal_id;
        this.shopDataForm.licence_img = res.licence_img;
        this.shopDataForm.licence_start = res.licence_start;
        this.shopDataForm.licence_end = res.licence_end;
        this.order_time_range.push(this.handleTime(res.licence_start * 1000));
        this.order_time_range.push(this.handleTime(res.licence_end * 1000));
      });
    },
    handleSaveShopData() {
      API_account.editShopInfo(this.shopDataForm).then(res => {
        console.log(res);
      });
      //   if (this.order_time_range) {
      //     this.params.start_time =
      //       this.order_time_range[0].getTime() / 1000;
      //     this.params.end_time =
      //       this.order_time_range[1].getTime() / 1000;
      //   }
    },
    handleTime(time) {
      //handleTime是获取今天的日期
      let date = new Date(time);
      let year = date.getFullYear();
      let month =
        date.getMonth() + 1 > 10
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1);
      let day = date.getDate() > 10 ? date.getDate() : "0" + date.getDate();
      return `${year}-${month}-${day}`;
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .site-logo .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  &:hover {
    border-color: #409eff;
  }
}
.site-logo-img {
  width: 120px;
  height: 120px;
  display: block;
}
.logo-uploader-icon {
  font-size: 20px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.zz {
  font-size: 18px;
  font-weight: bold;
  padding-left: 10px;
}
.shu {
  height: 30px;
  width: 6px;
  background-color: rgb(26, 67, 169);
  display: inline-block;
}
.he {
  padding-bottom: 30px;
}
.bg-settings {
  background: #fff;
  border: 1px solid #fafafa;
  padding: 10px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-radius: 10px;
}
.el-input {
  width: 400px;
}
</style>
